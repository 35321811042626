import styled from "styled-components"
import { parsedInt } from "./helperFunctions"

export type ResultStatus = "error"|"success"

export interface IUser {
  userId: number
  name: string
  email: string
  role: number
  isActive: boolean
  phoneNumber: string
  leagueId: number | null
  clubId: number | null
  createdAt: string
  updatedAt: string
  title: string
  lastAliasedClub: number | null
  stripeCustomerId: string | null
}

export interface IClub {
  clubId?: number
  name?: string
  nameLong?: string
  leagueId?: number
  secretToken?: string | null
  [key: string]: any
}

export interface ILeague {
  id: number
  name: string
  StateID?: string
  [key: string]: any
}

export interface ISwimmerWithoutGender {
  swimmerId: number
  lastName: string
  firstName: string
  middleInitials?: string | null
  dateOfBirth: string
  isActive: boolean
  createdAt: string
  createdByUserId: number
  updatedAt: string
  usaSwimmingId: string | null
  phoneNumber: string | null
}

export interface ISwimmer extends ISwimmerWithoutGender {
  gender: string
}

export interface ISeason {
  league: string | null
  seasonName: string
  seasonType: "summer" | "yearRound"
  startDate: string
  endDate: string
  seasonId: number
}

export interface IClubSeason extends ISeason {
  clubId: number
  club: string
  isSwimminglyCustomer: 0 | 1
  clubSeasonId: number
  didNotHaveSeason: number
  corporatePaymentId?: number
  clubPays?: number | null
  parentPays?: number | null
  leaguePays?: number | null
  defaultPaymentMethodId?: string | null
  signUpsOpenDate?: string | null
  isRegistrationEnabled: number
}

// this exists because we inherited a database structure that doesn't make sense and
// we unfortunately compounded the problem by making the mistake of sometimes converting
// gender to something that made sense on the server and other times sending it to the client
// in its raw form and converting there. this, along with many other data cleanup/standardization
// items, are super important to-dos, but also big projects unto themselves that will have to wait
// until at least after we're done with the parent payment process and possibly after we build out
// swimmer registrations in the app
export interface ISwimmerWithNumericGender extends ISwimmerWithoutGender {
  gender: 1 | 2
}

export interface IMeet {
  meetId: number
  meet_pin: number
  is_home: number
  unit_of_measure: 0 | 1 | 2
  location: string | null
  meetstatus: 0 | 2 | 1 | null
  meet_date: string
  meetName: string
  isVirtualMeet?: boolean
  virtualMeetId?: number
  poolId?: number
  startDate?: string
  endDate?: string
  virtualCourse?: 0 | 1 | 2 | null
  hasVirtualResults?: boolean
  lanesassignemnt?: 1 | 2
  laneassigmenttype?: 1 | 2
}

export interface IMeetClub {
  meetId: number
  clubId: number
  meetDeclarationsOpen: boolean
  meetDeclarationsCloseDate: string | null
  meetStatus:
    | "meet-created"
    | "entries-started"
    | "heat-sheet-ready"
    | "heat-sheet-merged"
    | "results-published"
}

export interface IMeetEvent {
  age_group: string
  distance: string
  event_order: number
  event_sort_order: number
  gender: string
  id: number
  is_relay: number
  meet_id: number
  race_type: number
  stroke: string
  unit_of_measure: number
}

// export type Guardian = {
//   userId: number
//   email: string
//   name: string
//   phone_number: string | null
//   isActive: boolean
//   role: number
//   swimmers: Array<{
//     swimmerId: number
//     last_name: string
//     first_name: string
//     date_of_birth: string
//     gender: string
//     isActive: boolean
//     guardianStatus: string
//   }>
// }
export interface IGuardian extends IUser {
  swimmers: Array<{ swimmer: ISwimmer; guardianStatus: string }>
}

export interface ISwimmerClubSeason {
  club: string
  clubId: number
  clubSignUpCode: string
  endDate: string
  hasPaid: 0 | 1
  league: string
  registered: 0 | 1
  registrationStatus: "signed-up" | "requested"
  season: string
  seasonId: number
  startDate: string
  swimmerShoulderNumber: number
  usingRegistration: number
}
// ISwimmerWithGuardian is the new one we're goig to use in place of IGuardiansSwimmer
export interface ISwimmerWithGuardian {
  swimmer: ISwimmerWithNumericGender
  swimmerShoulderNumber: number | null
  importedFromResults?: 0 | 1
  guardians: { user: IUser; guardianStatus: "confirmed" | "requested" }[]
  invitedGuardians?: {
    requestedGuardianId: number
    email: string
    swimmerId: number
    requestedBy: number
    requestedOn: string
    requestExpires: string
    requesterEmail: string
  }[]
  thisGuardianStatus: "confirmed" | "requested"
  clubSeasons?: ISwimmerClubSeason[]
}

// we're phasing out IGuardiansSwimmer in favor of ISwimmerWithGuardian
export interface IGuardiansSwimmer {
  swimmerId: number
  gender: "Boy" | "Girl"
  first_name: string
  last_name: string
  date_of_birth: string
  guardianStatus: "confirmed" | "requested"
  guardians?: {
    swimmerId: number
    guardianStatus: "confirmed" | "requested"
    name: string
    email: string
    phoneNumber: string | null
  }[]
  clubSeasons?: {
    seasonId: number
    season: string
    swimmerShoulderNumber: number
    hasPaid: 0 | 1
    league: string
    startDate: string
    endDate: string
    clubId: number
    club: string
    payingForMonth?: number | null
    registered: boolean
    registrationStatus: "signed-up" | "requested"
    signUpCode: string
  }[]
}

export const PaymentTypes = {
  byParent: "by parent",
  isByParent: (paymentType?: string) => {return paymentType === PaymentTypes.byParent},
  byClub: "by club",
  isByClub: (paymentType?: string) => {return paymentType === PaymentTypes.byClub},
  lumpSum: "offline by organization",
  isLumpSum: (paymentType?: string) => {return paymentType === PaymentTypes.lumpSum},
  unknown: "unexpected payment type",
  isUnknown: (paymentType: string) => {
    return !(
      PaymentTypes.isByParent(paymentType) ||
      PaymentTypes.isByClub(paymentType) ||
      PaymentTypes.isLumpSum(paymentType)
    )
  },
}

export const DateFormats = {
  default: "YYYY-MM-DD",
  monthDayYear: "MM-DD-YYYY",
  shortMonthAndDay: "MMM Do",
  monthDayYearTimestamp: "MM-DD-YYYY hh:mm A"
}

export const URLs = {
  swimminglyUniversity: "https://support.swimmingly.app",
  endpointHost: process.env.NODE_ENV === "development" ? "http://localhost:4000" : ""
}

export const Amounts = {
  membershipFeeForFormerLumpSumClubs: 1400
}

export const GenderFormatter = {
  format: (gender: string): string => {
    if (parsedInt(gender) === 1) {
      return "Girl"
    } else if (parsedInt(gender) === 2) {
      return "Boy"
    } else {
      return gender
    }
  } 
}

export interface IClubSeasonInfo {
  seasonId: number
  clubSeasonId: number
  clubId: number
  seasonName: string
  clubName: string
  leagueName: string
  startDate: string
  endDate: string
  cutOffType: "fixedCutOff" | "meetStart"
  cutOffDate: string | null
  isSwimminglyCustomer: 0 | 1
  parentPays: number | null
  clubPays: number | null
  leagueId: number
  leagueCity: string
  leagueState: string
  leaguePays: number | null
  seasonSetupConfirmedBy: number | null
  seasonSetupConfirmedOn: string | null
  setupConfirmedByName: string | null
  signUpsOpenDate: string | null
  usingRegistration: boolean
  corporatePaymentId: number | null
  paymentType:
    | "by parent"
    | "by club"
    | "hybrid parent and club"
    | "offline by organization"
    | "unexpected payment type"
  seasonTimeframe: "past" | "current" | "future"
}

export interface IAnnouncement {
  announcementId: number
  recipientUserId: number
  seasonId: number
  seasonName: string
  clubId: number
  clubName: number
  recipientEmailAddress: string
  senderUserId: number
  senderEmailAddress: string
  senderName: string
  role: number
  announcement: string
  allowResponses: boolean
  created_at: string
}

// referred to https://stackoverflow.com/questions/45741245/passing-a-generic-function-in-as-a-callback-in-typescript/45744177
// to get info on how to use generics in type definition
export type CallbackWithArg = <T>(t: T) => void | Promise<void>
export type CallbackNoArg = () => void | Promise<void>
export type Callback = CallbackWithArg | CallbackNoArg

// Trace Sauter - added code for a custom event emitter that I developed for
// a separate personal project and thought might be useful for us later on
// referenced https://lolahef.medium.com/react-event-emitter-9a3bb0c719 and
// https://www.freecodecamp.org/news/how-to-code-your-own-event-emitter-in-node-js-a-step-by-step-guide-e13b7e7908e1/
export class CustomEventEmitter {
  private _events: { [key: string]: Function[] }
  private _oneTimeEvents: { event: string; fn: Function }[]

  constructor() {
    this._events = {}
    this._oneTimeEvents = []
  }

  on(event: string, fn: Function) {
    if (this._events[event]) {
      this._events[event].push(fn)
    } else {
      this._events[event] = [fn]
    }
  }

  once(event: string, fn: Function) {
    let shouldAdd = true
    for (let i = 0; i < this._oneTimeEvents.length; i++) {
      const thisListener = this._oneTimeEvents[i]
      if (thisListener.event === event && thisListener.fn === fn) {
        shouldAdd = false
      }
    }
    if (shouldAdd) {
      this._oneTimeEvents.push({ event, fn })
    }
  }

  emit(event: string, ...data: any[]) {
    if (this._events[event]) {
      this._events[event].forEach((fn) => fn(...data))
    }
    for (let i = 0; i < this._oneTimeEvents.length; i++) {
      const thisListener = this._oneTimeEvents[i]
      if (thisListener.event === event) {
        thisListener.fn(data)
      }
    }
    this._oneTimeEvents = this._oneTimeEvents.filter(
      (evt) => evt.event !== event,
    )
  }

  removeListener(event: string, fn: Function) {
    if (this._events[event]) {
      let curIdx: number | undefined
      while ((curIdx = this._events[event].indexOf(fn)) !== -1) {
        this._events[event].splice(curIdx, 1)
      }
    }
    for (let i = 0; i < this._oneTimeEvents.length; i++) {
      const thisListener = this._oneTimeEvents[i]
      if (thisListener.event === event && thisListener.fn === fn) {
        this._oneTimeEvents.splice(i, 1)
        break
      }
    }
  }

  countListeners(event: string): number {
    let count = 0
    if (this._events[event]) count += this._events[event].length
    count += this._oneTimeEvents.filter((evt) => evt.event === event).length
    return count
  }

  removeAllListeners(event: string) {
    if (this._events[event]) {
      delete this._events[event]
    }
    this._oneTimeEvents = this._oneTimeEvents.filter(
      (evt) => evt.event !== event,
    )
  }
}

export const StyledWistia = styled.div`
  height: 349px;
  width: 620px;
  margin: auto;

  @media (min-width: 450px) {
    margin-bottom: 50px;
  }

  @media (max-width: 750px) {
    height: 253px;
    width: 450px;
  }

  @media (max-width: 450px) {
    height: 197px;
    width: 350px;
  }

  @media (max-width: 400px) {
    height: 175px;
    width: 90%;
  }
`
