// Need to use props.leagueId somewhere
import { useCallback, useEffect, useState } from "react"
import { Input } from "antd"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import InviteLeagueAdminAsHydroAdmin from "./InviteLeagueAdminAsHydroAdmin"
import AdminUsersAdmin from "./AdminUsersAdmin"
import CenterSpin from "./CenterSpin"
import {
  toLowerN,
  gen,
  roleMapping,
  IUser,
  ILeague,
  swimminglyApi,
  titleMap,
} from "./utils"
import ActionAlerts from "./ActionAlerts"

interface IUserWithLeague extends IUser {
  leagueName: string | null
}

export default function ManageAdminUsers() {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [adminUsers, setAdminUsers] = useState<IUserWithLeague[]>([])
  const [leaguesArr, setLeaguesArr] = useState<ILeague[]>([])
  const [loaded1, setLoaded1] = useState(false)
  const [loaded2, setLoaded2] = useState(false)
  const [value, setValue] = useState("")

  const sortClubs = (a: IUserWithLeague, b: IUserWithLeague) => {
    if (a.name > b.name) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    return 0
  }

  const getAdminUsers = useCallback(() => {
    swimminglyApi
      .post(gen("/api/getAdminUsers2Post"))
      .body({
        userId: user?.userId,
      })
      .then(
        ({
          status,
          users,
        }: {
          status: string
          users: { user: IUser; leagueName: string }[]
        }) => {
          if (status === "success") {
            const clubUsersArr: IUserWithLeague[] = []
            for (let i = 0; i < users.length; i++) {
              clubUsersArr.push({
                ...users[i].user,
                leagueName: users[i].leagueName,
              })
            }
            clubUsersArr.sort(sortClubs)
            setAdminUsers(clubUsersArr)
            setLoaded1(true)
          }
        },
      )

    swimminglyApi
      .post(gen("/api/getUsersLeaguesPost"))
      .body({ userId: user?.userId })
      .then((data) => {
        setLeaguesArr(data.leaguesArr)
        setLoaded2(true)
      })
  }, [user?.userId])

  useEffect(() => {
    getAdminUsers()
  }, [getAdminUsers])

  if (!(loaded1 && loaded2)) {
    return (
      <div>
        <header className="page-header">
          <h2>League Admins</h2>
        </header>

        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">League Admins</h2>
          </header>
          <div className="ourpanel-body">
            <CenterSpin />
          </div>
        </section>
      </div>
    )
  }

  let curUsers = adminUsers

  if (value) {
    curUsers = adminUsers.filter((el) => {
      let lowerState = toLowerN(value)
      const theTitle = titleMap.get(el.title)
      return (
        toLowerN(el.name).includes(lowerState) ||
        toLowerN(el.email).includes(lowerState) ||
        toLowerN(el.phoneNumber).includes(lowerState) ||
        toLowerN(roleMapping[el.role]).includes(lowerState) ||
        toLowerN(theTitle || "").includes(lowerState) ||
        toLowerN(el.leagueName || "").includes(lowerState) ||
        toLowerN(el.isActive ? "Active" : "Inactive").includes(lowerState)
      )
    })
  }

  return (
    <div>
      <header className="page-header">
        <h2>League Admins</h2>
      </header>
      <ActionAlerts />
      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">
            League Admins{" "}
            <span style={{ fontSize: "16px" }}>
              ({adminUsers.length} Users)
            </span>
          </h2>
        </header>
        <div className="ourpanel-body">
          <div style={{ marginBottom: "10px" }}>
            <InviteLeagueAdminAsHydroAdmin
              user={user}
              leaguesArr={leaguesArr}
            />
          </div>
          <Input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ marginBottom: "10px", width: "200px" }}
            placeholder="Search"
          />
          <AdminUsersAdmin
            getAdminUsers={getAdminUsers}
            adminUsers={curUsers}
            user={user}
          />
        </div>
      </section>
    </div>
  )
}
