import React from "react"
import $ from "jquery"
import EditUserLeagueModal from "./EditUserLeague"
import { roleMapping, titleMapping, gen } from "./utils"
import { Modal, Table, Button, message } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons"
const { Column } = Table
const { confirm } = Modal

function handleNull(a, b, func) {
  if (!a && b) {
    return 1
  }
  if (a && !b) {
    return -1
  }
  if (!a && !b) {
    return 0
  }
  if (a && b) {
    return func(a, b)
  }
}

export default class AdminUsersAdmin extends React.Component {
  state = {
    // Edit state
    approveUserLoading: false,
    userIdEdit: null,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: 3,
    title: "",
    visible: false,
    loading: false,
  }

  editUser = (user) => {
    let nameSplit = user.name.split(" ")
    this.setState({
      userIdEdit: user.id,
      email: user.email,
      firstName: nameSplit[0],
      lastName: nameSplit.slice(1, nameSplit.length).join(" "),
      phoneNumber: user.phone_number,
      role: String(user.role),
      title: user.title,
      visible: true,
    })
  }

  approveUser = (userId) => {
    this.setState({ approveUserLoading: true })
    $.ajax({
      url: gen("/api/activateThisUserPost"),
      method: "POST",
      dataType: "json",
      data: {
        userId: userId,
      },
    }).done((data) => {
      if (data.status === "error" && /did not get email/.test(data.error)) {
        console.log(data)
        message.error(data.error)
      } else {
        message.success("Activated user!")
      }
      this.setState({ approveUserLoading: false })
      this.props.getAdminUsers()
    })
  }

  deleteUser = (userId) => {
    if (!userId) {
      return
    }

    this.setState({ loading: true })
    $.ajax({
      url: gen("/api/deleteUserPost"),
      method: "POST",
      data: { id: userId },
    }).done((data) => {
      this.props.getAdminUsers()
      this.setState({ loading: false })
    })
  }

  render() {
    return (
      <div>
        <Table
          dataSource={this.props.adminUsers}
          bordered
          loading={this.state.loading}
          rowKey="id"
          size="middle"
        >
          <Column title="Email" dataIndex="email" key="email" />
          <Column title="Name" dataIndex="name" key="name" />
          <Column
            title="Phone Number"
            dataIndex="phone_number"
            key="phone_number"
          />
          <Column
            title="Role"
            dataIndex="role"
            key="role"
            render={(role, user) => {
              return (
                <span>
                  {roleMapping[user.role]} &nbsp;{" "}
                  <span>
                    {user.role === 3
                      ? user.leagueName && "(" + user.leagueName + ")"
                      : ""}
                  </span>
                </span>
              )
            }}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status, user) => {
              let activated = Number(user.activated)
              return (
                <span>
                  {activated === 1 ? "Active" : "Inactive"}{" "}
                  {activated === 0 && (
                    <div>
                      <Button
                        type="default"
                        size="small"
                        onClick={() => this.deleteUser(user.id)}
                        style={{ backgroundColor: "#f50", color: "white" }}
                      >
                        Reject
                      </Button>
                      <Button
                        loading={this.state.approveUserLoading}
                        type="default"
                        size="small"
                        onClick={() => this.approveUser(user.id)}
                        style={{
                          backgroundColor: "#369423",
                          color: "white",
                        }}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                </span>
              )
            }}
          />
          <Column
            title="Title"
            dataIndex="title"
            key="title"
            sorter={(user1, user2) =>
              handleNull(
                user1.title,
                user2.title,
                (title1, title2) => title1.length - title2.length,
              )
            }
            render={(title) => titleMapping[title]}
          />
          <Column
            title="Actions"
            key="actions"
            render={(user) => {
              return (
                <span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => this.editUser(user)}
                  >
                    <FontAwesomeIcon icon={faEdit} style={{ color: "grey" }} />
                  </span>{" "}
                  &nbsp;
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      let self = this
                      confirm({
                        title: "Hard Delete this user?",
                        content:
                          "This user will be permanently deleted from our database, and they will not be able to login anymore.",
                        onOk() {
                          self.deleteUser(user.id)
                        },
                        onCancel() {},
                      })
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} style={{ color: "grey" }} />
                  </span>
                </span>
              )
            }}
          />
        </Table>

        <EditUserLeagueModal
          userIdEdit={this.state.userIdEdit}
          email={this.state.email}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          phoneNumber={this.state.phoneNumber}
          title={this.state.title}
          user={this.props.user}
          visible={this.state.visible}
          setVisible={(val) => this.setState({ visible: val })}
          setLoading={(val) => this.setState({ loading: val })}
          getAdminUsers={this.props.getAdminUsers}
        />
      </div>
    )
  }
}
