  import React from "react"
  import $ from "jquery"
  import { validateEmail, gen } from "./utils"
  import PromoteDemoteUser from "./PromoteDemoteUser"
  import { Modal, message, Row, Col, Input, Select, Button } from "antd"
  const { Option } = Select

  class EditUserClubModal extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isEdit: true,
        userIdEdit: props.userIdEdit,

        email: props.email ? props.email : "",
        firstName: props.firstName ? props.firstName : "",
        lastName: props.lastName ? props.lastName : "",
        phoneNumber: props.phoneNumber ? props.phoneNumber : "",
        title: props.title ? props.title : "SwimminglyRep",
        // leagueId: (props.leaguesArr && props.leaguesArr[0] && props.leaguesArr[0].id) || null,
        role: "3",
        emailMessage: "",
        firstNameMessage: "",
        lastNameMessage: "",
        phoneNumberMessage: "",
        loading: false,
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      // console.log("in here")
      // only update chart if the data has changed
      this.setState({
        userIdEdit: nextProps.userIdEdit,

        email: nextProps.email ? nextProps.email : "",
        firstName: nextProps.firstName ? nextProps.firstName : "",
        lastName: nextProps.lastName ? nextProps.lastName : "",
        phoneNumber: nextProps.phoneNumber ? nextProps.phoneNumber : "",
        title: nextProps.title ? nextProps.title : "MeetCentralRep",
        // leagueId: (props.leaguesArr && props.leaguesArr[0] && props.leaguesArr[0].id) || null,
        role: "3",
      })
    }

    onSubmitFunc = (e) => {
      let isGood = this.onCheckValues()
      if (!isGood) {
        return
      }
      console.log(this.props)

      let data = {
        page: "club",
        email: this.state.email,
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
        PhoneNumber: this.state.phoneNumber,
        title: this.state.title,
        league_id: null,
        role: this.state.role,
        club_id: this.props.clubId,
        is_edit: true,
        id: this.state.userIdEdit,
        send_validation_now: false,
        password: null,
        userId: this.props.user.userId,
      }
      // console.log(data)
      $.ajax({
        url: gen("/api/registerPost"),
        method: "POST",
        data: data,
        dataType: "json",
        error: () => {
          message.error("Email already in use")
          this.setState({ loading: false })
        },
        success: (response) => {
          console.log(response.error)
          this.setState({ loading: false })
          this.props.setVisible(false)
          if (response.error) {
            message.error(response.error)
          } else {
            message.success("User edited successfully")
            if (this.props.callAfterSuccess) {
              this.props.callAfterSuccess()
            } else {
              window.location.reload()
            }
          }
        },
      })
    }

    onCheckValues = () => {
      let isGood = true
      if (!validateEmail(this.state.email)) {
        this.setState({ emailMessage: "Not a valid email" })
        isGood = false
      }

      // if (this.props.isOutside && !this.state.password.length > 4) {
      //   this.setState({ passwordMessage: "Password must be at least 4 characters long" })
      //   isGood = false
      // }

      // if (this.props.isOutside && this.state.password !== this.state.password2) {
      //   this.setState({ password2Message: "Passwords must be identical" })
      //   isGood = false
      // }

      if (!this.state.firstName) {
        this.setState({ firstNameMessage: "Not a valid first name" })
        isGood = false
      }

      if (this.state.firstName.length < 3) {
        this.setState({
          firstNameMessage: "First name must be at least 3 characters",
        })
        isGood = false
      }

      if (this.state.firstName.length > 255) {
        this.setState({
          firstNameMessage: "First name must be at less than 255 characters",
        })
        isGood = false
      }

      if (!this.state.lastName) {
        this.setState({ lastNameMessage: "Not a valid last name" })
        isGood = false
      }

      if (this.state.lastName.length < 3) {
        this.setState({
          lastNameMessage: "Last name must be at least 3 characters",
        })
        isGood = false
      }

      if (this.state.lastName.length > 255) {
        this.setState({
          lastNameMessage: "Last name must be at less than 255 characters",
        })
        isGood = false
      }

      if (!this.state.phoneNumber) {
        this.setState({ phoneNumberMessage: "Not a valid phone number" })
        isGood = false
      }
      let onlyDigits = this.state.phoneNumber.replace(/[^0-9]/, "")
      if (onlyDigits.length < 10) {
        this.setState({ phoneNumberMessage: "Needs at least 10 digits" })
        isGood = false
      }
      // if (!this.state.leagueId) {
      //   this.setState({ leagueIdMessage: "Need to select a league" })
      //   isGood = false
      // }
      return isGood
    }

    printClubInfo = () => {
      if (this.props.clubName && this.props.leagueName) {
        return `for ${this.props.clubName} in ${this.props.leagueName}`
      }

      if (this.props.clubName) {
        return `for ${this.props.clubName}`
      }
      return ""
    }

    render() {
      return (
          <Modal
            visible={this.props.visible}
            title="Edit User"
            onCancel={() => {
              this.setState({ loading: false })
              this.props.setVisible(false)
            }}
            footer={[
              <Button
                key="Cancel"
                onClick={() => {
                  this.setState({ loading: false })
                  this.props.setVisible(false)
                }}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={this.state.loading}
                onClick={() => {
                  this.setState({ loading: true })
                  this.onSubmitFunc()
                }}
              >
                Submit
              </Button>,
            ]}
          >
            <div>
              <Row style={{ paddingBottom: "15px" }}>
                <Col span={8}>Email</Col>
                <Col span={16}>
                  <Input
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={
                      () => {} /*(e) =>
                      this.setState({ email: e.target.value, emailMessage: "" })
                    */
                    }
                    placeholder="Email Address"
                    type="email"
                    disabled={true}
                  />
                  {this.state.emailMessage && (
                    <span id="spanUserName" className="field-validation-error">
                      {this.state.emailMessage}
                    </span>
                  )}
                </Col>
              </Row>

              <Row style={{ paddingBottom: "15px" }}>
                <Col span={8}>First Name</Col>
                <Col span={16}>
                  <Input
                    id="FirstName"
                    name="FirstName"
                    placeholder="First Name"
                    type="text"
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({
                        firstName: e.target.value,
                        firstNameMessage: "",
                      })
                    }
                  />
                  {this.state.firstNameMessage && (
                    <span id="spanUserName" className="field-validation-error">
                      {this.state.firstNameMessage}
                    </span>
                  )}
                </Col>
              </Row>

              <Row style={{ paddingBottom: "15px" }}>
                <Col span={8}>Last Name</Col>
                <Col span={16}>
                  <Input
                    name="LastName"
                    id="LastName"
                    placeholder="Last Name"
                    type="text"
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({
                        lastName: e.target.value,
                        lastNameMessage: "",
                      })
                    }
                  />
                  {this.state.lastNameMessage && (
                    <span id="spanUserName" className="field-validation-error">
                      {this.state.lastNameMessage}
                    </span>
                  )}
                </Col>
              </Row>

              <Row style={{ paddingBottom: "15px" }}>
                <Col span={8}>Phone Number</Col>
                <Col span={16}>
                  <Input
                    name="PhoneNumber"
                    id="PhoneNumber"
                    placeholder="Phone Number"
                    type="text"
                    value={this.state.phoneNumber}
                    onChange={(e) =>
                      this.setState({
                        phoneNumber: e.target.value,
                        phoneNumberMessage: "",
                      })
                    }
                  />
                  {this.state.phoneNumberMessage && (
                    <span id="spanUserName" className="field-validation-error">
                      {this.state.phoneNumberMessage}
                    </span>
                  )}
                </Col>
              </Row>
           
              {this.state.role !== 5 && (
                <Row style={{ paddingBottom: "15px" }}>
                  <Col span={8}>Title</Col>
                  <Col span={16}>
                    <Select
                      span={100}
                      style={{ width: "100%" }}
                      id="person_title"
                      name="title"
                      value={this.state.title}
                      onChange={(val) => this.setState({ title: val })}
                    >
                      <Option value="SwimminglyRep">Swimmingly Rep</Option>
                      <Option value="HeadCoach">Head Coach</Option>
                      <Option value="Assistant Coach">Assistant Coach</Option>
                      <Option value="ClubTreasure">Club Treasurer</Option>
                      <Option value="LeaguePresident">League President</Option>
                      <Option value="LeagueTreasurer">League Treasurer</Option>
                      <Option value="League Board">League Board</Option>
                    </Select>
                  </Col>
                </Row>
              )}
            

              <Row>
                <Col span={8}>Permission Level</Col>
                <Col span={16}>
                  <PromoteDemoteUser
                    userId={this.props.user.userId}
                    targetUserId={this.props.userIdEdit}
                  />
                </Col>
              </Row>
            </div>
          </Modal>
      )
    }
  }

  export default EditUserClubModal
