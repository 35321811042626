import React from "react"
import $ from "jquery"
import { validateEmail, gen } from "./utils"
import { Modal, message, Button, Input, Col } from "antd"

// Nice to have
// Sort Manage Admin Users by LAST name (requires db update)
// Edit Admin User on Ok, needs button spin, and to close the modal
export default class InviteUserModal extends React.Component {
  state = { email: "", emailMessage: "", visible: false, loading: false }

  onCheckEmail = () => {
    let email = this.state.email

    if (!validateEmail(email)) {
      this.setState({ emailMessage: "Not a valid email" })
      return
    }

    this.setState({ loading: true })
    $.ajax({
      url: gen(`/api/checkLeagueEmailPost`),
      method: "POST",
      dataType: "json",
      data: {
        email: email,
        leagueId: this.props.leagueId,
      },
    }).done((data) => {
      // These cases are for your league
      if (data["case"] === "notInDB") {
        this.onSendEmail()
      } else if (data["case"] === "invalidLeague") {
        this.setState({ emailMessage: "This is an invalid league" })
      } else if (data["case"] === "clubAdmin") {
        this.setState({
          emailMessage:
            "This user is already a club admin for your league. Contact swimmingly to promote them.",
        })
      } else if (data["case"] === "parent") {
        this.setState({
          emailMessage:
            "This user is already a parent listed in your league. Contact Swimmingly to promote them.",
        })
      } else if (data["case"] === "leagueAdmin") {
        this.setState({
          emailMessage: "This user is already a league admin in your league.",
        })
      } else if (data["case"] === "notInLeague") {
        this.setState({
          emailMessage:
            "This user is already in the Clubhouse for a different club/league. You cannot invite them to your league. Please use a different email.",
        })
      } else {
        console.log(data)
        // console.log("not in db")
      }
      this.setState({ loading: false })
    })
  }

  onSendEmail = () => {
    let email = this.state.email
    this.setState({ loading: true })
    $.ajax({
      url: gen(`/api/inviteAdminPost`),
      method: "POST",
      dataType: "json",
      data: {
        clubId: null,
        leagueId: this.props.leagueId,
        email: email,
      },
    }).done((data) => {
      message.success(
        "User successfully invited. They will appear as a user once they accept your invitation.",
      )
      this.setState({ loading: false, visible: false })
    })
  }

  render() {
    return (
      <div>
        <Button type="primary" onClick={() => this.setState({ visible: true })}>
          Invite League Admin
        </Button>

        <Modal
          visible={this.state.visible}
          title="Invite League Admin"
          onOk={this.onCheckEmail}
          onCancel={() => this.setState({ visible: false })}
          confirmLoading={this.state.loading}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Col span={8}>Email Address</Col>
            <Col span={16}>
              <Input
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ email: e.target.value, emailMessage: "" })
                }
                placeholder="Email Address"
                type="email"
              />
              {this.state.emailMessage && (
                <span className="field-validation-error">
                  {this.state.emailMessage}
                </span>
              )}
            </Col>
          </div>
        </Modal>
      </div>
    )
  }
}
