import React from "react"
import $ from "jquery"
import { validateEmail, gen } from "./utils"
import { Modal, message, Button, Input, Col, Row, Select } from "antd"
const { Option } = Select
export default class InviteUserModal extends React.Component {
  constructor(props) {
    super(props)
    // console.log(props.leaguesArr)
    this.state = {
      email: "",
      emailMessage: "",
      leagueId:
        props.leaguesArr && props.leaguesArr[0] && props.leaguesArr[0].id,
      visible: false,
      loading: false,
    }
  }

  onCheckEmail = () => {
    let email = this.state.email

    if (!validateEmail(email)) {
      this.setState({ emailMessage: "Not a valid email" })
      return
    }

    this.setState({ loading: true })
    $.ajax({
      url: gen(`/api/checkLeagueEmailPost`),
      method: "POST",
      dataType: "json",
      data: {
        email: email,
        leagueId: this.state.leagueId,
      },
    }).done((data) => {
      console.log(data)
      // These cases are for your league
      if (data["case"] === "notInDB") {
        console.log("abcdefg")
        this.onSendEmail()
      } else if (data["case"] === "invalidLeague") {
        this.setState({ emailMessage: "This is an invalid league" })
      } else if (data["case"] === "clubAdmin") {
        this.setState({
          emailMessage:
            "This user is already a club admin for your league. Contact swimmingly to promote them.",
        })
      } else if (data["case"] === "parent") {
        this.setState({
          emailMessage:
            "This user is already a parent listed in your league. Contact Swimmingly to promote them.",
        })
      } else if (data["case"] === "leagueAdmin") {
        this.setState({
          emailMessage: "This user is already a league admin in your league.",
        })
      } else if (data["case"] === "notInLeague") {
        this.setState({
          emailMessage:
            "This user is already in the Clubhouse for a different club/league. You cannot invite them to your league. Please use a different email.",
        })
      } else {
        console.log(data)
        // console.log("not in db")
      }
      this.setState({ loading: false })
    })
  }

  onSendEmail = () => {
    let email = this.state.email
    this.setState({ loading: true })
    $.ajax({
      url: gen(`/api/inviteAdminPost`),
      method: "POST",
      dataType: "json",
      data: {
        clubId: null,
        leagueId: this.state.leagueId,
        email: email,
      },
    }).done((data) => {
      message.success(
        "User successfully invited. They will appear as a user once they accept your invitation.",
      )
      this.setState({ visible: false, loading: false })
    })
  }

  render() {
    let sortLeagues = this.props.leaguesArr.sort((a, b) =>
      a.name < b.name ? -1 : 1,
    )
    return (
      <div>
        <Button type="primary" onClick={() => this.setState({ visible: true })}>
          Invite League Admin
        </Button>
        <Modal
          visible={this.state.visible}
          title="Invite League Admin"
          onOk={this.onCheckEmail}
          onCancel={() => this.setState({ visible: false })}
          confirmLoading={this.state.loading}
        >
          <Row>
            <Col span={9}>Email Address</Col>
            <Col span={15}>
              <Input
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ email: e.target.value, emailMessage: "" })
                }
                placeholder="Email Address"
                type="email"
              />
              {this.state.emailMessage && (
                <span className="field-validation-error">
                  {this.state.emailMessage}
                </span>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={9} />
            <Col span={15}>
              <Select
                style={{ width: "100%" }}
                value={this.state.leagueId}
                onChange={(val) => this.setState({ leagueId: val })}
              >
                {sortLeagues.map((el, inx) => {
                  return (
                    <Option key={`leagueOptions-${inx}`} value={el.id}>
                      {el.name}
                    </Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}
