import React from "react"
import $ from "jquery"
import EditUserClubModal from "./EditUserClub"
import { roleMapping, titleMapping, gen } from "./utils"
import { Button, message, Tooltip } from "antd"
import { EditOutlined } from "@ant-design/icons"

// Add in refresh page
export default class AdminUsersClub extends React.Component {
  state = {
    // Edit state
    approveUserLoading: false,
    userIdEdit: null,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: 3,
    title: "",
  }

  editUser = (user) => {
    let nameSplit = user.name.split(" ")
    this.setState({
      userIdEdit: user.id,
      email: user.email,
      firstName: nameSplit[0],
      lastName: nameSplit.slice(1, nameSplit.length).join(" "),
      phoneNumber: user.phone_number,
      role: String(user.role),
      title: user.title,
      visible: true,
    })
  }

  approveUser = (userId) => {
    this.setState({ approveUserLoading: true })
    $.ajax({
      url: gen("/api/activateThisUserPost"),
      method: "POST",
      dataType: "json",
      data: { userId: userId },
    })
      .done((data) => {
        if (data.status === "error" && /did not get email/.test(data.error)) {
          console.log(data)
          message.error(data.error)
        } else {
          message.success("Activated user!")
        }
        this.setState({ approveUserLoading: false })
        this.props.reloadUserData()
        // window.location.reload()
      })
      .catch((error) => {
        console.log(error)
        message.error("Huh, something went wrong... try again!")
        this.setState({ approveUserLoading: false })
      })
  }

  deleteUser = (userId) => {
    if (!userId) {
      return
    }

    $.ajax({
      url: gen("/api/deleteUserPost"),
      method: "POST",
      data: { id: userId },
    }).done((data) => {
      window.location.reload()
    })
  }

  render() {
    if (this.props.adminUsers.length === 0) {
      return (
        <div className="row">
          <div className="col-md-12 center">
            <h3>No Club User Found</h3>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div>
          <table id="checkerTable" className="background-red">
            <thead>
              <tr>
                <th>Email Address</th>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Role</th>
                <th>Status</th>
                <th>Title</th>
                <th>Edit User</th>
              </tr>
            </thead>
            <tbody>
              {this.props.adminUsers.map((user) => {
                let activated = Number(user.activated)
                return (
                  <tr
                    key={user.id}
                    style={
                      activated === 0 ? { backgroundColor: "#FFF9C4" } : null
                    }
                  >
                    <td>{user.email}</td>
                    <td>{user.name}</td>
                    <td>{user.phone_number}</td>
                    <td>
                      {roleMapping[user.role]} &nbsp;{" "}
                      <span>
                        {user.role === 3
                          ? user.hasleague && "(" + user.hasleague.name + ")"
                          : ""}
                      </span>
                    </td>
                    <td>
                      {activated === 1 ? "Active" : "Inactive"}{" "}
                      {activated === 0 && (
                        <div>
                          <Button
                            type="default"
                            size="small"
                            onClick={() => this.deleteUser(user.id)}
                            style={{ backgroundColor: "#f50", color: "white" }}
                          >
                            Reject
                          </Button>
                          <Button
                            type="default"
                            size="small"
                            loading={this.state.approveUserLoading}
                            onClick={() => this.approveUser(user.id)}
                            style={{
                              backgroundColor: "#369423",
                              color: "white",
                            }}
                          >
                            Approve
                          </Button>
                        </div>
                      )}
                    </td>
                    <td>{titleMapping[user.title]}</td>
                    <td>
                      <span
                        onClick={() => this.editUser(user)}
                        style={{ cursor: "pointer" }}
                      >
                        <Tooltip title="Edit this user's name, phone number contact, title, promote/demote or unaffiliate this user from your club.">  
                        <EditOutlined />
                        </Tooltip>
                      </span>{" "}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <EditUserClubModal
          userIdEdit={this.state.userIdEdit}
          email={this.state.email}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          phoneNumber={this.state.phoneNumber}
          title={this.state.title}
          visible={this.state.visible}
          setVisible={(val) => this.setState({ visible: val })}
          clubId={this.props.club && this.props.club.clubId}
          user={this.props.user}
        />
      </div>
    )
  }
}
