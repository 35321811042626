import React from "react"
import $ from "jquery"
import { roleMapping, titleMapping, gen } from "./utils"
import EditUserLeagueModal from "./EditUserLeague"
import { Modal, Button, message } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons"
const { confirm } = Modal

export default class AdminUsersLeague extends React.Component {
  state = {
    // Edit state
    approveUserLoading: false,
    userIdEdit: null,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: 3,
    title: "",
    visible: false,
    buttonLoading: false,
  }

  editUser = (user) => {
    let nameSplit = user.name.split(" ")
    this.setState({
      userIdEdit: user.id,
      email: user.email,
      firstName: nameSplit[0],
      lastName: nameSplit.slice(1, nameSplit.length).join(" "),
      phoneNumber: user.phone_number,
      role: String(user.role),
      title: user.title,
      visible: true,
    })
  }

  approveUser = (userId) => {
    this.setState({ approveUserLoading: true })
    $.ajax({
      url: gen("/api/activateThisUserPost"),
      method: "POST",
      dataType: "json",
      data: { userId: userId },
    }).done((data) => {
      if (data.status === "error" && /did not get email/.test(data.error)) {
        console.log(data)
        message.error(data.error)
      } else {
        message.success("Activated user!")
      }
      this.setState({ approveUserLoading: false })
      this.props.getClubUsers()
    })
  }

  deleteUser = (userId) => {
    if (!userId) {
      return
    }
    this.setState({ buttonLoading: true })
    $.ajax({
      url: gen("/api/deleteUserPost"),
      method: "POST",
      data: { id: userId },
    }).done((data) => {
      this.setState({ buttonLoading: false })
      this.props.getClubUsers()
    })
  }

  render() {
    if (this.props.adminUsers.length === 0) {
      return (
        <div className="row">
          <div className="col-md-12 center">
            <h3>No League User Found</h3>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="form-group ">
          <table
            className="table table-bordered table-striped mb-none rosterListing"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th>Email Address</th>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Role</th>
                <th>Status</th>
                <th>Title</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.adminUsers.map((user) => {
                let activated = Number(user.activated)
                return (
                  <tr
                    key={user.userId || user.id}
                    style={
                      activated === 0 ? { backgroundColor: "#FFF9C4" } : null
                    }
                  >
                    <td>{user.email}</td>
                    <td>{user.name}</td>
                    <td>{user.phone_number}</td>
                    <td>
                      {roleMapping[user.role]} &nbsp;{" "}
                      <span>
                        {user.role === 3
                          ? user.hasleague && "(" + user.hasleague.name + ")"
                          : ""}
                      </span>
                    </td>
                    <td>
                      {activated === 1 ? "Active" : "Inactive"}{" "}
                      {activated === 0 && (
                        <div>
                          <Button
                            type="default"
                            size="small"
                            onClick={() => this.deleteUser(user.id)}
                            style={{ backgroundColor: "#f50", color: "white" }}
                          >
                            Reject
                          </Button>
                          <Button
                            loading={this.state.approveUserLoading}
                            type="default"
                            size="small"
                            onClick={() => this.approveUser(user.id)}
                            style={{
                              backgroundColor: "#369423",
                              color: "white",
                            }}
                          >
                            Approve
                          </Button>
                        </div>
                      )}
                    </td>
                    <td>{titleMapping[user.title]}</td>
                    <td>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => this.editUser(user)}
                        title="Edit"
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: "grey" }}
                        />
                      </span>{" "}
                      &nbsp;
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let self = this
                          confirm({
                            title: "Hard Delete this user?",
                            content:
                              "This user will be permanently deleted from our database, and they will not be able to login anymore.",
                            onOk() {
                              self.deleteUser(user.id)
                            },
                            onCancel() {},
                          })
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ color: "grey" }}
                        />
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <EditUserLeagueModal
          userIdEdit={this.state.userIdEdit}
          email={this.state.email}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          phoneNumber={this.state.phoneNumber}
          title={this.state.title}
          user={this.props.user}
          visible={this.state.visible}
          setVisible={(val) => this.setState({ visible: val })}
          setLoading={(val) => this.setState({ loading: val })}
          getAdminUsers={this.props.getClubUsers}
        />
      </div>
    )
  }
}
