import React from "react"
import $ from "jquery"
import InviteClubAdmin from "./InviteClubAdmin"
import AdminUsersClub from "./AdminUsersClub"
// import ManageParents from "./ManageParents"
import CenterSpin from "./CenterSpin"
import ClubBlock from "./ClubBlock"
import { gen } from "./utils"
import ActionAlerts from "./ActionAlerts"

export default class ManageClubUsers extends React.Component {
  state = {
    adminUsers: [],
    loaded: false,
  }

  componentDidMount() {
    this.getClubUsers()
  }

  getClubUsers = () => {
    $.ajax({
      url: gen("/api/getClubUsers2Post"),
      method: "POST",
      dataType: "json",
      data: {
        user: this.props.user,
        clubId: this.props.club.clubId,
      },
    }).done((data) => {
      this.setState({ adminUsers: data.clubUsersArr, loaded: true })
    })
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <header className="page-header">
            <h2>Club Admins</h2>
          </header>

          <ClubBlock />

          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">Club Admins</h2>
            </header>
            <div className="ourpanel-body">
              <CenterSpin />
            </div>
          </section>
        </div>
      )
    }
    return (
      <div>
        <header className="page-header">
          <h2>Club Admins</h2>
        </header>

        <ActionAlerts />

        <ClubBlock />

        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">
              Club Admins{" "}
              <span style={{ fontSize: "16px" }}>
                ({this.state.adminUsers.length} users)
              </span>
            </h2>
          </header>
          <div className="ourpanel-body">
            <InviteClubAdmin
              user={this.props.user}
              clubId={this.props.club.clubId}
            />
            <br />
            <AdminUsersClub
              reloadUserData={this.getClubUsers}
              adminUsers={this.state.adminUsers}
              club={this.props.club}
              user={this.props.user}
            />
          </div>
        </section>
        {/*<ManageParents user={this.props.user} club={this.props.club} />*/}
      </div>
    )
  }
}
