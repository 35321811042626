import React, { useEffect, useState } from "react"
import { useStoreState, useStoreActions, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { validateEmail, gen, IClub, swimminglyApi } from "./utils"
import { message, Row, Col, Input, Select, Button, Tag, Drawer, Card, Tabs, Divider, Tooltip, List, Statistic, Space } from "antd"
import { MailOutlined, UserOutlined, BulbOutlined, NotificationOutlined, AppleOutlined, AndroidOutlined } from "@ant-design/icons"
import HelpCenter from "../images/HelpCenter.png"
import HappySwimTeam from "../images/HappySwimTeam.png"
import EmailSupport from "../images/email-support-icon.png"
import PhoneSupport from "../images/phone-support-icon.png"
import ProvideFeedback from "../images/feedback-icon.png"
import FacebookCommunity from "../images/Facebook_Community.png"
import { Colors } from "./ClubSeasonManagement/styles"
import { FaLifeRing } from "react-icons/fa"
const { Option } = Select

const { TabPane } = Tabs;

interface IEditUserProfileProps {
  visible: boolean
  setVisible: (value: boolean) => void
}

export default function EditUserProfile({
  visible,
  setVisible,
}: IEditUserProfileProps) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const setUser = useStoreActions((actions: AppDataStore) => actions.setUser)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [title, setTitle] = useState("")
  const [role, setRole] = useState(0)
  const [email, setEmail] = useState("")
  const [emailMessage, setEmailMessage] = useState("")
  const [firstNameMessage, setFirstNameMessage] = useState("")
  const [lastNameMessage, setLastNameMessage] = useState("")
  const [phoneNumberMessage, setPhoneNumberMessage] = useState("")
  const [userClubId, setUserClubId] = useState<number | undefined>(undefined)
  const [leagueClubs, setLeagueClubs] = useState<IClub[]>([])
  const [triggerRefresh, setTriggerRefresh] = useState(0)
  const [loading, setLoading] = useState(false)

  const sortClubs = (a: IClub, b: IClub) => {
    const aName = a.name && a.name.toLowerCase()
    const bName = b.name && b.name.toLowerCase()

    if (!aName || !bName) return 0

    if (aName > bName) {
      return 1
    }
    if (aName < bName) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    const nameSplit: Array<string> = user?.name.split(" ") || ["", ""]
    if (nameSplit?.length > 0) setFirstName(nameSplit[0])
    if (nameSplit?.length > 1)
      setLastName(nameSplit.slice(1, nameSplit.length).join(" "))

    setPhoneNumber(user?.phoneNumber || "")
    setTitle(user?.title || "")
    setRole(user?.role || 0)
    setEmail(user?.email || "")
    setEmailMessage("")
    setFirstNameMessage("")
    setLastNameMessage("")
    setPhoneNumberMessage("")
    setUserClubId(user?.clubId || -1)
  }, [user, triggerRefresh, visible])

  useEffect(() => {
    const nameSplit: Array<string> = user?.name.split(" ") || ["", ""]
    if (nameSplit?.length > 0) setFirstName(nameSplit[0])
    if (nameSplit?.length > 1)
      setLastName(nameSplit.slice(1, nameSplit.length).join(" "))
  }, [user?.name])

  useEffect(() => {
    setPhoneNumber(user?.phoneNumber || "")
  }, [user?.phoneNumber])

  useEffect(() => {
    setEmail(user?.email || "")
  }, [user?.email])

  useEffect(() => {
    setRole(user?.role || 0)
  }, [user?.role])

  useEffect(() => {
    if (user?.role === 3 || user?.role === 2) {
      swimminglyApi
        .post(gen("/api/allClubsInLeaguePost"))
        .body({ leagueId: user.leagueId })
        .then((data) => {
          data.clubs.sort(sortClubs)
          setLeagueClubs(data.clubs)
        })
    }
  }, [user?.userId, user?.role, user?.leagueId])

  useEffect(() => {
    if (user?.clubId) setUserClubId(user.clubId)
  }, [user?.clubId])

  useEffect(() => {
    setTitle(user?.title || "")
  }, [user?.title])

  const onSubmitFunc = () => {
    let isGood = onCheckValues()
    if (!isGood) {
      return
    }
    setLoading(true)

    let data = {
      page: "profile",
      userId: user?.userId,
      name: `${firstName} ${lastName}`,
      phoneNumber,
      title,
      userClubId,
    }
    swimminglyApi
      .post(gen("/api/editUser"))
      .body(data)
      .then((response) => {
        setLoading(false)
        setVisible(false)
        if (response.error) {
          message.error(response.error)
        } else if (response.user) {
          setUser(response.user)
          message.success("Your profile changes saved successfully!")
        } else {
          message.warn("Huh, something may have gone wrong...")
        }
      })
      .catch(() => {
        message.error("Can't perform this action")
        setLoading(false)
      })
  }

  const onCheckValues = () => {
    let isGood = true

    if (!user?.email || !validateEmail(user?.email)) {
      setEmailMessage("Not a valid email")
      isGood = false
    }

    if (!firstName) {
      setFirstNameMessage("Not a valid first name")
      isGood = false
    } else if (firstName.length < 1) {
      setFirstNameMessage("First name must be at least 1 character")
      isGood = false
    } else if (firstName.length > 255) {
      setFirstNameMessage("First name must be at less than 255 characters")
      isGood = false
    }

    if (!lastName) {
      setLastNameMessage("Not a valid last name")
      isGood = false
    }

    if (lastName.length < 2) {
      setLastNameMessage("Last name must be at least 2 characters")
      isGood = false
    }

    if (lastName.length > 255) {
      setLastNameMessage("Last name must be at less than 255 characters")
      isGood = false
    }

    if (!phoneNumber) {
      setPhoneNumberMessage("Not a valid phone number")
      isGood = false
    }

    const onlyDigits = phoneNumber.replace(/[^0-9]/, "")
    if (onlyDigits.length < 10) {
      setPhoneNumberMessage("Needs at least 10 digits")
      isGood = false
    }

    return isGood
  }

  return (
    <Drawer
      visible={visible}
      width="auto"
      placement="right"
      onClose={() => {
        setLoading(false)
        setVisible(false)
        setTriggerRefresh(triggerRefresh + 1)
      }}

    >
      <Tabs
        defaultActiveKey="1"
      >
        <TabPane
          tab={
            <span>
              <UserOutlined />
              My Profile
            </span>
          }
          key="1">

          <div>
            <br />
            <Row style={{ maxWidth: "400px", paddingBottom: "15px", }}>
              <Col span={6}>Email</Col>
              <Col span={18}>
                <Input
                  prefix={<MailOutlined />}
                  value={email}
                  onChange={() => { }}
                  placeholder="Email Address"
                  type="email"
                  disabled={true}
                />
                {emailMessage && (
                  <span id="spanUserName" style={{ color: "var(--rejectionred)" }}>
                    {emailMessage}
                  </span>
                )}
              </Col>
            </Row>

            <Row style={{ maxWidth: "400px", paddingBottom: "15px" }}>
              <Col span={6}>First Name</Col>
              <Col span={18}>
                <Input
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value)
                    setFirstNameMessage("")
                  }}
                />
                {firstNameMessage && (
                  <span id="spanUserName" style={{ color: "var(--rejectionred)" }}>
                    {firstNameMessage}
                  </span>
                )}
              </Col>
            </Row>

            <Row style={{ maxWidth: "400px", paddingBottom: "15px" }}>
              <Col span={6}>Last Name</Col>
              <Col span={18}>
                <Input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value)
                    setLastNameMessage("")
                  }}
                />
                {lastNameMessage && (
                  <span id="spanUserName" style={{ color: "var(--rejectionred)" }}>
                    {lastNameMessage}
                  </span>
                )}
              </Col>
            </Row>

            <Row style={{ maxWidth: "400px", paddingBottom: "15px" }}>
              <Col span={6}>Phone</Col>
              <Col span={18}>
                <Input
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value)
                    setPhoneNumberMessage("")
                  }}
                />
                {phoneNumberMessage && (
                  <span id="spanUserName" style={{ color: "var(--rejectionred)" }}>
                    {phoneNumberMessage}
                  </span>
                )}
              </Col>
            </Row>

            {role !== 5 && (
              <Row style={{ maxWidth: "400px", paddingBottom: "15px" }}>
                <Col span={6}>Title</Col>
                <Col span={18}>
                  <Select
                    style={{ width: "100%" }}
                    value={title}
                    onChange={setTitle}
                  >
                    <Option value="SwimminglyRep">Swimmingly Rep</Option>
                    <Option value="HeadCoach">Head Coach</Option>
                    <Option value="Assistant Coach">Assistant Coach</Option>
                    <Option value="ClubTreasure">Club Treasurer</Option>
                    <Option value="LeaguePresident">League President</Option>
                    <Option value="LeagueTreasurer">League Treasurer</Option>
                    <Option value="League Board">League Board</Option>
                  </Select>
                </Col>
              </Row>
            )}

            {(role === 3 || role === 2) && (
              <Row style={{ maxWidth: "400px", paddingBottom: "15px" }}>
                <Col span={6}>Your Club</Col>
                <Col span={18}>
                  <Select
                    style={{ width: "100%" }}
                    value={userClubId}
                    onChange={setUserClubId}
                  >
                    <Option value="">No Active Club</Option>
                    {leagueClubs.map((el) => (
                      <Option value={el.clubId as number} key={el.clubId}>
                        {el.name_long}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            )}
            <div>
              <Button
                key="submit"
                type="primary"
                block={true}
                icon={<UserOutlined />}
                loading={loading}
                onClick={() => {
                  onSubmitFunc()
                }}
              >
                Save Changes
              </Button>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                color: "#8898aa"
              }}
            >
              Your Clubhouse permission level
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "left",
              }}
            >
              <Tooltip
                title="Manage all clubs in your league"
                mouseEnterDelay={1.0}
                placement="bottom"
                color={Colors.veryDarkBlue}
              >
                <Tag color={role === 3 ? "#25bfea" : "#a9bfcf"}>
                  League Admin
                </Tag>
              </Tooltip>
              <Tooltip
                title="Manage your club only"
                mouseEnterDelay={1.0}
                placement="bottom"
                color={Colors.veryDarkBlue}
              >
                <Tag color={role === 4 ? "#25bfea" : "#a9bfcf"}>
                  Club Admin
                </Tag>
              </Tooltip>
              <Tooltip
                title="Manage your swimmers, register new swimmers, and invite other guardians to follow your swimmers.
                  At your admin's descretion, guardians can register for the season and pay their club, complete meet declarations, access heat
                  sheets and results. View personalized athlete profiles for each of your swimmers at anytime!"
                placement="bottom"
                mouseEnterDelay={1.0}
                color={Colors.veryDarkBlue}
              >
                <Tag color={role === 5 ? "#25bfea" : "#a9bfcf"}>Parent/Guardian
                </Tag>
              </Tooltip>
            </div>
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <FaLifeRing />
              &nbsp;Support
            </span>
          }
          key="2"
        >
          <div className="site-card-wrapper">
            {role !== 5 ? (
              <Row gutter={16}>
                <Col flex={2}>
                  <Card
                    title={<a href="https://academy.swimmingly.app/" target="_blank">Swimmingly Academy</a>}
                    bordered={true}
                    size="default"
                    type="inner"
                    cover={
                      <img
                        alt="Go to Swimmingly Academy"
                        src={HappySwimTeam} 
                        style={{ maxWidth: 250, maxHeight: 250, margin: "auto", width: "50%", padding: "10px" }}
                      />
                    }
                  >
                    Enroll in Swimmingly Academy and take
                    <br />
                    a free course, designed just for you!
                    <br />
                    Learn your role for your swim team 
                    <br />
                    in minutes!
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col flex={2}>
                  <Card
                    title={<a href="https://academy.swimmingly.app/p/swimmingly-parent" target="_blank">Swimmingly Academy</a>}
                    bordered={true}
                    size="default"
                    type="inner"
                    cover={
                      <img
                        alt="Go to Swimmingly Academy"
                        src={HappySwimTeam} 
                        style={{ maxWidth: 250, maxHeight: 250, margin: "auto", width: "50%", padding: "10px" }}
                      />
                    }
                  >
                    Enroll in Swimmingly Academy and take
                    <br />
                    a free course, designed just for you!
                    <br />
                    Learn your role for your swim team
                    <br />
                    in minutes!
                  </Card>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col flex={2}>
                <Card
                  title={<a href="https://support.swimmingly.app" target="_blank">Help Center</a>}
                  bordered={true}
                  size="default"
                  type="inner"
                  cover={
                      <img
                        alt="Help Center"
                        src={HelpCenter}
                        style={{ maxWidth: 250, maxHeight: 250, margin:"auto", width:"50%", padding:"10px" }}
                      />
                  }
                >
                  Your knowledge base for quick how-to
                  <br />
                  tutorials. This is a great resource for
                  <br />
                  self-service, education, and discovery
                  <br />
                  of new features.
                </Card>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col flex={2}>
                <Card
                  title={<a href="mailto:team@swimmingly.app">Email Support</a>}
                  bordered={true}
                  size="default"
                  type="inner"
                  cover={
                    <img
                      alt="Email Support"
                      src={EmailSupport}
                      style={{ maxWidth: 150, maxHeight: 150, margin:"auto", width:"50%", padding:"10px" }}

                    />
                  }
                >
                  If you can't find an answer to your
                  <br />
                  question, <a href="mailto:team@swimmingly.app">Email us for support</a>.
                </Card>
              </Col>
            </Row>
            {role !== 5 && (
              <Row gutter={16}>
                <Col flex={2}>
                  <Tooltip
                    title="Phone Support on the pool deck is available to North American customers"
                    placement="left"
                    color={Colors.veryDarkBlue}
                  >
                    <Card

                      title="Phone Support"
                      bordered={true}
                      size="default"
                      type="inner"
                      cover={
                        <img
                          alt="Phone Support"
                          src={PhoneSupport}
                          style={{ maxWidth: 150, maxHeight: 150, margin:"auto", width:"50%", padding:"10px" }}
                        />
                      }
                    >
                      The Swimmingly hotline is available
                      <br />
                      to administrators for timely phone
                      <br />
                      calls while on the pool deck.
                      <br />
                      (866) 377-SWIM (7946)
                    </Card>
                  </Tooltip>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col flex={2}>
                <Card
                  title={<a href="https://survey.hsforms.com/1c3AQf-6zSNSRoHrfJ0lmRQcapxb" target="_blank">Provide Feedback</a>}
                  bordered={true}
                  size="default"
                  type="inner"
                  cover={
                    <img
                      alt="Provide Feedback"
                      src={ProvideFeedback}
                      style={{ maxWidth: 150, maxHeight: 150, margin:"auto", width:"50%", padding:"10px" }}
                    />
                  }
                >
                  Provide Feedback to
                  <br />
                  the Swimmingly Team.
                  <br />
                  Help us improve the platform!
                </Card>
              </Col>
            </Row>
            {role !== 5 && (
              <Row gutter={16}>
                <Col flex={2}>
                  <Card
                    title={<a href="https://www.facebook.com/groups/1127566450663770" target="_blank">Facebook Community</a>}
                    bordered={true}
                    size="default"
                    type="inner"
                    cover={
                      <img
                        alt="Join the Swimmingly Facebook Community"
                        src={FacebookCommunity}
                        style={{ maxWidth: 150, maxHeight: 150, margin:"auto", width:"50%", padding:"10px" }}
                      />
                    }
                  >
                    Connect, learn, and share
                    <br />
                    with other users on the
                    <br />
                    <a href="https://www.facebook.com/groups/1127566450663770" target="_blank">Swimmingly Facebook Community</a>!
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </TabPane>

        <TabPane
          tab={
            <span>
              <NotificationOutlined />
              Updates
            </span>
          }
          key="3"
        >
          <p>
            <Tooltip
              title="Go to Device Compatibility"
              placement="right"
              color={Colors.primaryBlue}
              mouseEnterDelay={0.5}
            >
              <Tag
                color={Colors.primaryBlue}
              >
                <a href="https://support.swimmingly.app/device-compatibility" target="_blank">Current App Versions</a>
              </Tag>
            </Tooltip>
          </p>

          <Row
            gutter={3}
          >
            <Col
              span={12}
            >
              <Card
                hoverable={true}
                style={{height: '100%', boxSizing: 'border-box'}}
              >
                  <Statistic
                    title="Swimmingly"
                    value="11.0.5"
                    valueStyle={{ color: Colors.primaryBlue }}
                    prefix={<AppleOutlined />}
                  />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                hoverable={true}
                style={{height: '100%', boxSizing: 'border-box'}}
              >
                  <Statistic
                    title="Swimmingly Timer"
                    value="11.0.3"
                    valueStyle={{ color: Colors.primaryBlue }}
                    prefix={<AndroidOutlined />}
                  />
              </Card>
            </Col>
          </Row>
          <Row
            gutter={3}
          >
            <Col span={12}>
              <Card
                hoverable={true}
                style={{height: '100%', boxSizing: 'border-box'}}
              >
               
                  <Statistic
                    title="SwimminglyFan"
                    value="3.0.4"
                    valueStyle={{ color: Colors.primaryBlue }}
                    prefix={<AppleOutlined />}
                  />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                hoverable={true}
                style={{height: '100%', boxSizing: 'border-box'}}
              >
                  <Statistic
                    title="SwimminglyFan"
                    value="3.0.7"
                    valueStyle={{ color:  Colors.primaryBlue  }}
                    prefix={<AndroidOutlined />}
                  />
              </Card>
            </Col>
          </Row>
          <Divider />   
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tooltip
                      title="Go to Announcement"
                      placement="right"
                      color={Colors.announcementBlue}
                      mouseEnterDelay={0.5}
                    >
                    <Tag
                        color={Colors.announcementBlue}
                        
                    >
                    <a href="https://splash.swimmingly.app/announcement" target="_blank">"No WiFi Setup" Announcement - Click to Watch</a>
                    </Tag>
                    </Tooltip>
                  }
                description="Did you know? The legacy Swimmingly WiFi is no longer a requirement to use Swimmingly. If you
                            missed this announcement, then check it out today!"
              />
            </List.Item>
          
            <Divider />
          <p>
            <Tooltip
              title="Go to Features Updates"
              placement="right"
              color={Colors.veryDarkBlue}
              mouseEnterDelay={0.5}
            >
              <Tag
                color="#87d068">
                <a href="https://swimmingly.app/updates" target="_blank">Update Release Notes</a>
              </Tag>
            </Tooltip>
          </p>
          <List>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v3.0.7 SwimminglyFan (Android)
                    </Tag>
                  }
                description="07/01/2024. 
                Update: Time improvement (best time) UI performance update. Relay entries UI performance update."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v11.0.5 Swimmingly (iOS)
                    </Tag>
                  }
                description="06/07/2024. 
                Update: Minor performance update to improve post-meet publishing on Scorekeeper's iPad."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Clubhouse - Heat Sheet PDF (2 columns)
                    </Tag>
                  }
                description="06/07/2024. 
                New heat sheet PDF features 2 columns and saves paper compared to the legacy html heat sheet.
                Features include empty lanes visible per heat for real-time management, 'placeholders' if coach doesn't
                fill in all 4 relay slots, and maximum readability!" 
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Clubhouse - Time Improvement Ribbon Labels PDF
                    </Tag>
                  }
                description="06/06/2024. 
                Time improvement ribbon labels PDF. Located under your meet reports. Maximum flexibility to 
                filter your meet searches by desired age groups first, then download your PDF."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Clubhouse - DQ Ribbon Labels PDF
                    </Tag>
                  }
                description="06/06/2024. 
                DQ ribbon labels PDF. Located under Reports > Meets > DQ Reports. Boost morale with ribbons for your DQs
                - a great educational tool for young swimmers!"
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Clubhouse - Heat Sheet PDF (3 columns)
                    </Tag>
                  }
                description="06/03/2024. 
                New heat sheet PDF features 3 columns and saves over 100% more paper than the legacy html heat sheet.
                Features include empty lanes visible per heat for real-time management, 'placeholders' if coach doesn't
                fill in all 4 relay slots, and maximum readability!"
              />
            </List.Item>
          <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v11.0.4 Swimmingly (iOS)
                    </Tag>
                  }
                description="05/28/2024. 
                Update: Minor performance update under-the-hood when a Scorekeeper rejoins a swim meet in progress."
              />
            </List.Item>
          <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v11.0.3 Swimmingly (iOS)
                    </Tag>
                  }
                description="05/20/2024.
                Updates: significant increase in compatible Android devices for Swimmingly Timer.
                Significantly improved battery performance across all roles on iOS and Android.
                New dark mode clocks for Starter and Timers with enhanced UI.
                New dark mode for Judge/Head Referee with enhanced UI.
                Significant scan & huddle performance improvements.
                Increased performance across iOS and Android."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v11.0.3 Swimmingly Timer (Android)
                    </Tag>
                  }
                description="05/20/2024.
                Updates: significant increase in compatible Android devices for Swimmingly Timer.
                Significantly improved battery performance across all roles on iOS and Android.
                New dark mode clocks for Starter and Timers with enhanced UI.
                New dark mode for Judge/Head Referee with enhanced UI.
                Significant scan & huddle performance improvements.
                Increased performance across iOS and Android."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Clubhouse - Promote Parent/Guardians 
                    </Tag>
                  }
                description="04/2024. Club and league admins can now promote parent/guardians to club or league
                admins, based upon their permission level. This means, club &
                league admins can promote and demote any user in their organization based on their needs."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Clubhouse - Roster Header Sorting
                    </Tag>
                  }
                description="04/2024. Your roster can be sorted intelligently by clicking on the corresponding header."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Clubhouse - Entries "By Swimmer" Update 
                    </Tag>
                  }
                description="02/2024. Now, coaches can see a swimmer's best time before clicking-to-enter the swimmer, 
                when using the entries 'by swimmer' feature. Performance update under-the-hood processes
                entries faster, making this feature more user-friendly."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Swimmingly Academy
                    </Tag>
                  }
                description="01/2024. You can access free courses to train & certify anyone
                for any role involved with your swim team. Successfully delegate by instructing staff,
                volunteers, and parents to take a Swimmingly Academy course!"
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v3.0.6 SwimminglyFan (iOS)
                    </Tag>
                  }
                description="12/2023. Added user management capabilities. New parent/guardian accounts 
                created on the Clubhouse automatically sync to create a SwimminglyFan account."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v3.0.4 SwimminglyFan (Android)
                    </Tag>
                  }
                description="12/2023. Added user management capabilities. New parent/guardian accounts 
                created on the Clubhouse automatically sync to create a SwimminglyFan account."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v3.0.5 SwimminglyFan (Android)
                    </Tag>
                  }
                description="12/2023. Samsung compatibility update - significantly increased support for late-model devices across the Samsung lineup."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v11.0.1 Swimmingly Timer (Android)
                    </Tag>
                  }
                description="12/2023. Samsung compatibility update - significant camera-scanning support for late-model Samsung devices."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v11.0.0 Swimmingly (iOS)
                    </Tag>
                  }
                description="10/2023. Initial version 11 release of Swimmingly (iOS) & Swimmingly Timer (Android)."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    v11.0.0 Swimmingly Timer (Android)
                    </Tag>
                  }
                description="10/2023. Initial version 11 release of Swimmingly (iOS) & Swimmingly Timer (Android)."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Club Communication
                    </Tag>
                  }
                description="Create announcements in-app in the Clubhouse as well as sending email notifications to your families. Choose whether your announcements
                              can be replied to via email or not."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                  title={
                    <Tag
                        color="green"
                    >
                    Swimmingly v10
                    </Tag>
                  }
                description="The 10th anniversary of Swimmingly features a Swimmingly mobile app that no longer requires the Swimmingly WiFi.
                            For more information, watch the 2023 Announcement or check out the Help Center."
              />
            </List.Item>
          <List.Item
            >
              <List.Item.Meta
                title={
                  <Tag
                      color="green"
                  >
                  PREMIUM Registration: Waivers
                  </Tag>
                }
                description="During PREMIUM Registration, customized waivers now include clickable hyperlinks for any URLs. 
                              Additionally, any waiver changes are now updated in real-time (and can be previewed in real-time)."
              />
              </List.Item>
            <List.Item
            >
              <List.Item.Meta
                title={
                  <Tag
                      color="green"
                  >
                  Guardian Registration Receipts
                  </Tag>
                }
                description="Guardians paying for BASIC or PREMIUM Registration will be able to access more detailed receipts under their account. Guardians completing PREMIUM 
                              registrations will see a detailed breakdown of additional checkout items and discounts in their subtotals on their receipts."
              />
              </List.Item>
              <List.Item
            >
              <List.Item.Meta
                title={
                  <Tag
                      color="green"
                  >
                  PREMIUM Club Registration
                  </Tag>
                }
                description="Collect revenue for your team by creating custom club registration dues for swim families, custom waivers,
                      add additional checkout items (merchandise), and create custom discounts for checkout."
              />
            </List.Item>
            <List.Item
            >
              <List.Item.Meta
                title={
                  <Tag
                      color="green"
                  >
                  Parent/Guardians: Streamlined Navigation
                  </Tag>
                }
                description="Streamlined mobile-friendly navigation for the parent//guardian permission level in the Clubhouse that makes completing
                meet declarations and viewing results much simpler."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
              title={
                <Tag
                    color="green"
                >
                Entries Enhancements
                </Tag>
              }
                description="Your lineup and entries screen has been updated to make tracking real-time entries easier, with streamlined UI and navigation tools."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
              title={
                <Tag
                    color="green"
                >
                Guardians: Invite Users for your Swimmers
                </Tag>
              }
                description="Invite other users to sync with your swimmer's profile. These users can complete meet
                      declarations, view personalized results for that swimmer, and more."
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <Tag
                      color="green"
                  >
                  League Admins: League Leaderboard
                  </Tag>
                }
                description="Access the league leaderboard and run top times across your league,
                      gain insight into championship meet seeding, and more. "
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <Tag
                      color="green"
                  >
                  Swimmingly Timers: Haptic Feedback
                  </Tag>
                }
                description="Swimmingly Timers now enjoy 'haptic feedback' when they stop their clocks. Enjoy the 'feel'
                      of stopping your clock, without looking at your device!"
              />
            </List.Item>
          </List>
        </TabPane>
      </Tabs>
    </Drawer>
  )
}
