import React from "react"
import $ from "jquery"
import { validateEmail, gen } from "./utils"
import { Modal, message, Button, Row, Col, Input } from "antd"

export default class InviteUserModal extends React.Component {
  state = { email: "", emailMessage: "", visible: false, loading: false }

  onCheckEmail = () => {
    let email = this.state.email

    if (!validateEmail(email)) {
      this.setState({ emailMessage: "Not a valid email" })
      return
    }

    this.setState({ loading: true })
    $.ajax({
      url: gen("/api/checkClubEmailPost"),
      method: "POST",
      dataType: "json",
      data: {
        email: email,
        clubId: this.props.clubId,
      },
    }).done((data) => {
      console.log(data)
      // These cases are for your league
      if (data["case"] === "notInDB") {
        this.onSendEmail()
      } else if (data["case"] === "invalidClub") {
        this.setState({
          emailMessage: "This is an invalid club. Contact Swimmingly support.",
        })
      } else if (data["case"] === "notAllowed") {
        this.setState({
          emailMessage:
            "Cannot invite. This user is already in our db. Use a different email address.",
        })
      } else if (data["case"] === "leagueAdmin") {
        this.setState({
          emailMessage: `Cannot invite. This user is already a league admin for league "${data.user.league.name}". Use a different email address.`,
        })
      } else if (data["case"] === "clubAdmin") {
        this.setState({
          emailMessage: `Cannot invite. This user is already a club admin for club "${data.user.club.name}". Use a different email address.`,
        })
      } else if (data["case"] === "parent") {
        if (!data.canPromote) {
          this.setState({
            emailMessage: `User already exists. You can promote this user to Club Admin by going to Manage Roster > Parent/Guardians.`,
          })
        } else {
          this.setState({
            emailMessage: `Cannot invite. This user is already a parent in our system for your club. Would you like to promote them to club admin?`,
          })
        }
      }
      this.setState({ loading: false })
    })
  }

  onSendEmail = () => {
    let email = this.state.email
    this.setState({ loading: true })
    $.ajax({
      url: gen("/api/inviteAdminPost"),
      method: "POST",
      data: {
        clubId: this.props.clubId,
        leagueId: null,
        email: email,
      },
    }).done((data) => {
      message.success(
        "User successfully invited. They will appear as a user once they accept your invitation.",
      )
      this.setState({ visible: false, loading: false })
    })
  }

  render() {
    return (
      <div>
        <Button type="primary" onClick={() => this.setState({ visible: true })}>
          Invite Club Admin
        </Button>

        <Modal
          visible={this.state.visible}
          title="Invite Club Admin"
          onOk={this.onCheckEmail}
          onCancel={() => this.setState({ visible: false })}
          confirmLoading={this.state.loading}
        >
          <Row>
            <Col span={8}>Email Address</Col>
            <Col span={16}>
              <Input
                id="email"
                name="email"
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ email: e.target.value, emailMessage: "" })
                }
                placeholder="Email Address"
                type="email"
              />
              {this.state.emailMessage && (
                <span id="spanUserName" className="field-validation-error">
                  {this.state.emailMessage}
                </span>
              )}
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}
