import { useState } from "react";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { Menu, Typography, Tooltip, Alert, Divider, MenuProps } from "antd";
import { SettingOutlined, UserOutlined, DeleteOutlined, CopyOutlined, TrophyOutlined, MergeCellsOutlined, PlusOutlined, DollarOutlined, FileTextOutlined, ImportOutlined, UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { fuzzyRank } from "./utils";
import SearchInput from "./SearchInput";
import UserManagement from "./UserManagement";  // Import the UserManagement component
import LumpSumPayment from "./LumpSumPayment";  // Import the Corporate/Offline payment record component
import UpdateLumpSumPayment from './UpdateLumpSumPayment';  // Import the UpdateLumpSumPayment component
import CreateSeason from "./CreateSwimSeason";  //Import the CreateSeason component
import UpdateSwimSeason from "./Update Swim Season";
import MergeSwimmers from "./MergeSwimmers";
import CopyEventOrder from "./CopyEventOrder";
import DeleteVirtualMeet from "./DeleteVirtualMeet";

const { Title, Text } = Typography;

const MainSection = styled.div`
  background-color: var(--snow);

  .ant-input {
    border-radius: 3px 0px 0px 3px;
    margin-bottom: 25px;
    font-size: 17px;
    height: 40px;
  }

  @media (max-width: 576px) {
    .ant-input {
      display: block;
      width: 100%;
      margin: 0px;
      padding: 14px;
    }
  }
`;

type MenuItem = {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  description?: string;
  content?: JSX.Element;
  children?: MenuItem[];
};

const menuItems: MenuItem[] = [
  {
    key: "usermanagement",
    label: "Alias into any User",
    icon: <UserOutlined />,
    description: "Login as any user. Note: you cannot register swimmers aliased into user, using this feature.",
    content: <UserManagement />,  // Use the UserManagement component here
  },
  {
    key: "seasonmanagement",
    label: "Manage Seasons",
    icon: <SettingOutlined />,
    children: [
      {
        key: "createseason",
        label: "Create Season",
        icon: <PlusOutlined />,
        description: "Create a new season",
        content: <CreateSeason />,
      },
      {
        key: "updateseason",
        label: "Update Season",
        icon: <UploadOutlined />,
        description: "Update an existing season",
        content: <UpdateSwimSeason />,
      }
    ]
  },
  {
    key: "corporateofflinepayments",
    label: "Corporate/Offline Payments",
    icon: <ImportOutlined />,
    children: [
      {
        key: "lumpsumpayment",
        label: "Add Corporate/Offline Payment",
        description: "Add a corporate / offline payment record",
        content: <LumpSumPayment />,
      },
      {
        key: "updatelumpsumpayment",
        label: "Update Corporate/Offline Payment",
        description: "Update a corporate / offline payment record ",
        content: <UpdateLumpSumPayment />,
      }
    ]
  },
  {
    key: "mergeswimmers",
    label: "Merge Swimmers",
    icon: <MergeCellsOutlined />,
    description: "Merge duplicate swimmer entries",
    content: <MergeSwimmers />,
  },
  {
    key: "copyeventorder",
    label: "Copy Event Order",
    icon: <CopyOutlined />,
    description: "Copy the event order from another meet",
    content: <CopyEventOrder />,
  },
  {
    key: "deletevirtualmeet",
    label: "Delete Virtual Meet",
    icon: <DeleteOutlined />,
    description: "Delete a virtual meet",
    content: <DeleteVirtualMeet />,
  },
];

export default function SwimminglyAdminFunctionality() {
  const [searchString, setSearchString] = useState("");
  const history = useHistory();
  const [current, setCurrent] = useState<string>("usermanagement");
  const [currentContent, setCurrentContent] = useState<JSX.Element>(<UserManagement />);  // Set default content to UserManagement

  const onSearchChange = debounce((val) => {
    setSearchString(val);
  }, 350);

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key as string);
    const selectedItem = menuItems.find((item) => item?.key === e.key);
    if (selectedItem && selectedItem?.content) {
      setCurrentContent(selectedItem.content);
    } else {
      menuItems.forEach((item) => {
        if (item?.children) {
          const childItem = item?.children.find((child) => child?.key === e.key);
          if (childItem && childItem?.content) {
            setCurrentContent(childItem.content);
          }
        }
      });
    }
  };

  let filteredMenuItems = menuItems;

  if (searchString !== "") {
    filteredMenuItems = fuzzyRank(menuItems, (item) => item?.label?.toString() || '', searchString);
  }

  const clubhouseVersion = process.env.REACT_APP_CLUBHOUSE_VERSION;

  return (
    <>
      <header className="page-header">
        <Title level={2}>Swimmingly Control Panel</Title>
      </header>
      <Alert
        message="Internal Tools"
        description="These are internal Swimmingly administrative tools. These tools are not available external to the Swimmingly Team."
        type="info"
        showIcon
        closable={true}
        style={{ marginTop: 20 }}
      />
      <Divider />
      <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal">
        {filteredMenuItems.map((item) => {
          if (item?.children) {
            return (
              <Menu.SubMenu key={item?.key} icon={item?.icon} title={item?.label}>
                {item?.children.map((child) => (
                  <Menu.Item key={child?.key}>
                    <Tooltip title={child?.description}>
                      <span>{child?.label}</span>
                    </Tooltip>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }
          return (
            <Menu.Item key={item?.key} icon={item?.icon}>
              <Tooltip title={item?.description}>
                <span>{item?.label}</span>
              </Tooltip>
            </Menu.Item>
          );
        })}
      </Menu>

      <MainSection>
        {currentContent}
      </MainSection>
      {clubhouseVersion ? `Swimmingly Clubhouse ${clubhouseVersion}` : null}
    </>
  );
}
